import React, { useEffect } from "react"
import SEO from "../../components/seo"

// 🎨 UI
import { Box, Heading, Text, Link, Flex } from "@chakra-ui/react"
import SinglePageWrapper from "../../components/Contents/SinglePageWrapper"
import ForschungsFilter from "../../components/Forschung/ForschungsFilter"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

export default function Forschung({ data }) {
  const posts = data.allWpWissenschaftsschwerpunkt.nodes
  const settings = data.wpModeSettings
  const institute = data.allWpInstitut.nodes
  const forschung = data.allWpWissenschaft.nodes
  const sendActionToStore = useDispatch()

  useEffect(() => {
    sendActionToStore({
      type: "change/translatedSchwerpunktSlug",
      payload: "",
    })
  }, [sendActionToStore])
  // console.log("POSTS", posts)
  // console.log("FORSCHUNG", forschung)
  // console.log("SETTINGS", settings)
  // console.log("INSTITUTE", institute)
  return (
    <>
      <SEO title={settings.acf_research_settings.textOverviewTitle} />

      <SinglePageWrapper background="blue.50">
        <Box width={["100%", "100%", "100%", "75%"]} maxWidth="640px" mb="64px">
          <Heading
            as="h1"
            fontSize={{ base: "xl", md: "2xl" }}
            mb="24px"
            maxWidth="640px"
            lineHeight="1.2"
          >
            {settings.acf_research_settings.textOverviewTitle}
          </Heading>
          <Text>{settings.acf_research_settings.textOverviewDescription}</Text>
        </Box>

        <ForschungsFilter
          posts={posts}
          stringData={settings}
          institute={institute}
          forschung={forschung}
        />
      </SinglePageWrapper>
    </>
  )
}

export const query = graphql`
  query ForschungSchwerpunkte($lang: String!) {
    allWpWissenschaftsschwerpunkt(
      filter: { locale: { locale: { eq: $lang } } }
    ) {
      nodes {
        title
        slug
        acf_forschungsschwerpunkt {
          textBeschreibung
        }
      }
    }
    allWpInstitut {
      nodes {
        name
        description
        acf_institute {
          link
        }
      }
    }
    wpModeSettings(
      slug: { eq: "forschung" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_research_settings {
        textOverviewTitle
        textOverviewDescription
        textTitelForschungsschwerpunkt
        linkText
        forschungspartnerTitel
        projektbeschreibung
        forschungsbegriffe {
          institution
        }
      }
      acf_filter_settings {
        altesteZuerst
        filterAnzeigen
        filterSchliesen
        neusteZuerst
        disziplin
        jahr
        kategorie
        keyword
        schwerpunktthema
        name
        suche
        sortieren
        alleFilterEntfernen
        keineForschungsprojekteGefunden
      }
    }
    allWpWissenschaft(filter: { locale: { locale: { eq: $lang } } }) {
      nodes {
        acf_forschung {
          groupProjektbeteiligte {
            textAutoren {
              name
            }
            textInstitution
            disziplin {
              name
              tax_translation {
                enTranslation
              }
            }
          }
          links {
            url
          }
          imageFeatureImage {
            altText
            localFile {
              childImageSharp {
                fluid(quality: 60) {
                  src
                  srcSet
                }
              }
              publicURL
            }
          }
          groupSuchrelevanteEintrage {
            year
            anhangKategorie {
              name
              tax_translation {
                enTranslation
              }
            }
            keyword {
              name
              tax_translation {
                enTranslation
              }
            }
          }
          projektbeschreibung {
            textProjektbeschreibungText
            textProjektbeschreibungTitle
          }
          relationSchwerpunktthema {
            ... on WpWissenschaftsschwerpunkt {
              id
              title
              slug
            }
          }
        }
        title
        slug
      }
    }
  }
`
