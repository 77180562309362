import React from "react"

import { Box, Heading, Link, Text } from "@chakra-ui/react"

import { cleanStringForAnchor } from "../../tools/helper"

import Forschungsschwerpunkt from "../Forschung/Forschungsschwerpunkt"

const Forschungsschwerpunkte = ({
  schwerpunkte,
  institute,
  forschung,
  stringData,
}) => {
  return (
    <Box mt="64px">
      {schwerpunkte.map((schwerpunkt, index) => (
        <Forschungsschwerpunkt
          slug={schwerpunkt?.slug}
          forschung={forschung}
          stringData={stringData}
          index={index}
          schwerpunkt={schwerpunkt}
          key={index}
        />
      ))}

      <Heading
        as="h3"
        size="md"
        color="modeGray.500"
        mt="128px"
        mb="24px"
        id={`${cleanStringForAnchor("Labs & Institute der Forschungspartner")}`}
      >
        {stringData.acf_research_settings.forschungspartnerTitel}
      </Heading>

      <Box width={["100%", "100%", "100%", "75%"]} maxWidth="640px">
        {institute.map((institut, index) => (
          <Box key={institut + index} mb="64px">
            <Heading
              as="h4"
              size="xl"
              mb="24px"
              id={`${cleanStringForAnchor(
                "Labs & Institute der Forschungspartner"
              )}`}
            >
              {institut.name}
            </Heading>
            <Text mb="16px">{institut.description}</Text>
            {institut.acf_institute?.link ? (
              <Link
                href={institut.acf_institute?.link}
                alt={institut.name}
                fontWeight="bold"
                fontSize="lg"
              >
                {stringData.acf_research_settings.linkText}
              </Link>
            ) : (
              ""
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Forschungsschwerpunkte
